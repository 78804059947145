'use client';

import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

const Galaxy = () => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!containerRef.current) return;

        const container = containerRef.current; // Store the current ref value
        const width = container.clientWidth;
        const height = container.clientHeight;

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(60, width / height, 0.1, 1000);
        camera.position.z = 6; // Set the camera position to 6

        const renderer = new THREE.WebGLRenderer({ alpha: true });
        renderer.setSize(width, height);
        container.appendChild(renderer.domElement);

        const particlesGeometry = new THREE.BufferGeometry();
        const particlesCount = 5000;
        const positions = new Float32Array(particlesCount * 3);

        for (let i = 0; i < particlesCount; i++) {
            const theta = THREE.MathUtils.randFloatSpread(360);
            const phi = THREE.MathUtils.randFloatSpread(360);
            const radius = Math.random() * 2 + 0.5; // Smaller radius
            const x = radius * Math.sin(theta) * Math.cos(phi);
            const y = radius * Math.sin(theta) * Math.sin(phi);
            const z = radius * Math.cos(theta);

            positions.set([x, y, z], i * 3);
        }

        particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));

        const particlesMaterial = new THREE.PointsMaterial({
            size: 0.015,
            color: '#ffffff', // Change color to white
            sizeAttenuation: true,
            depthWrite: false,
        });

        const particles = new THREE.Points(particlesGeometry, particlesMaterial);
        scene.add(particles);

        const animate = () => {
            requestAnimationFrame(animate);
            particles.rotation.y += 0.001;
            renderer.render(scene, camera);
        };

        animate();

        return () => {
            if (container) {
                container.removeChild(renderer.domElement);
            }
        };
    }, []);

    return <div ref={containerRef} className="w-full h-full" />;
};

const Loader = () => {
    return (
        <div className="w-full h-screen flex items-center justify-center bg-black">
            <div className="w-1/2 h-1/2"> {/* Adjust the size of the container */}
                <Galaxy />
            </div>
            <div className="absolute bottom-4 left-0 right-0 text-center">
                <p className="text-white text-lg font-semibold animate-pulse">Loading...</p>
            </div>
        </div>
    );
};

export default Loader;
