import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa'; // Importing a React icon
import { motion, AnimatePresence } from 'framer-motion'; // Importing Framer Motion for animation

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) { // Show button after scrolling down 100px
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.button
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }} // Animation for hiding
          transition={{ duration: 0.3 }}
          className="fixed bottom-4 right-4 bg-blue-500 text-white p-3 rounded-full shadow-lg" // Tailwind CSS classes
          onClick={handleScrollToTop}
        >
          <FaArrowUp size={24} /> {/* React icon */}
        </motion.button>
      )}
    </AnimatePresence>
  );
};

export default ScrollToTopButton;
