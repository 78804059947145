'use client'

import { motion } from 'framer-motion'
import { FaBriefcase, FaStar } from 'react-icons/fa'

const jobs = [
  {
    title: 'Full Stack Developer',
    company: 'Variacode',
    period: 'Jun 2024 - Aug 2024',
    description: 'Worked on full-stack development projects, focusing on delivering quality web solutions.'
  },
  {
    title: 'Full Stack Developer',
    company: 'Datasur',
    period: 'Feb 2024 - May 2024',
    description: 'Developed and maintained web applications, ensuring optimal performance and user experience.'
  },
  {
    title: 'Full Stack Developer',
    company: 'Duoc UC IVARAS',
    period: 'Jul 2023 - Jan 2024',
    description: 'Collaborated with teams to create efficient and scalable web applications.'
  },
  {
    title: 'Python Developer',
    company: 'Duoc UC IVARAS',
    period: 'Mar 2023 - Jun 2023',
    description: 'Focused on backend development using Python, enhancing application functionalities.'
  },
  {
    title: 'Web Developer',
    company: 'Freelance',
    period: 'Mar 2022 - Feb 2024',
    description: 'Provided freelance web development services, specializing in both frontend and backend solutions.'
  }
]

export default function JobExperienceTimeline() {
  return (
    <motion.div
      className="min-h-screen text-white p-4 sm:p-8"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8 sm:mb-12">My Cosmic Career Journey</h2>
      <div className="max-w-3xl mx-auto relative">
        {/* Vertical line - hidden on mobile, visible on desktop */}
        <div className="hidden sm:block absolute left-1/2 transform -translate-x-1/2 w-1 h-full bg-blue-900"></div>

        {jobs.map((job, index) => (
          <motion.div
            key={index}
            className={`flex flex-col sm:flex-row items-center mb-8 sm:mb-16 ${index % 2 === 0 ? 'sm:flex-row-reverse' : ''}`}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <div className={`w-full sm:w-1/2 ${index % 2 === 0 ? 'sm:pl-8' : 'sm:pr-8'}`}>
              <motion.div
                className="bg-gray-900 p-6 rounded-lg shadow-lg relative border-2 border-transparent bg-clip-border"
                whileHover={{ scale: 1.02 }}
                transition={{ type: 'spring', stiffness: 300 }}
                style={{
                  borderImage: 'linear-gradient(to right, #1e3a8a, #3b82f6, #9333ea, #f59e0b) 1',
                  boxShadow: '0 0 10px rgba(59, 130, 246, 0.5)',
                  animation: 'gradient-border 20s linear infinite', // Slower animation
                }}
              >
                {/* Mobile timeline dot */}
                <div className="absolute -left-3 top-1/2 transform -translate-y-1/2 sm:hidden">
                  <motion.div
                    className="w-6 h-6 bg-blue-900 rounded-full flex items-center justify-center"
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ type: 'spring', stiffness: 300, delay: index * 0.2 + 0.3 }}
                  >
                    {index === 0 ? (
                      <FaStar className="w-3 h-3 text-yellow-300" />
                    ) : (
                      <FaBriefcase className="w-3 h-3 text-white" />
                    )}
                  </motion.div>
                </div>
                <h3 className="text-xl sm:text-2xl font-semibold mb-2">{job.title}</h3>
                <p className="text-blue-300 mb-2">{job.company}</p>
                <p className="text-sm text-indigo-400 mb-4">{job.period}</p>
                <p className="text-gray-300 text-sm sm:text-base">{job.description}</p>
              </motion.div>
            </div>
            {/* Desktop timeline dot */}
            <div className="hidden sm:flex w-1/2 justify-center">
              <motion.div
                className="w-12 h-12 bg-blue-900 rounded-full flex items-center justify-center"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ type: 'spring', stiffness: 300, delay: index * 0.2 + 0.3 }}
              >
                {index === 0 ? (
                  <FaStar className="w-6 h-6 text-yellow-300" />
                ) : (
                  <FaBriefcase className="w-6 h-6 text-white" />
                )}
              </motion.div>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  )
}
