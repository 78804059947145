import { FaGithub, FaLinkedin, FaEnvelope } from 'react-icons/fa'

export default function Footer() {
  return (
    <footer className="bg-black bg-opacity-50 backdrop-blur-md py-6 text-center">
      <div className="flex justify-center space-x-4">
        <a href="https://github.com/Emiliooo90" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition-colors">
          <FaGithub size={24} />
        </a>
        <a href="https://www.linkedin.com/in/emilio-romero-pereira-6129221ba" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition-colors">
          <FaLinkedin size={24} />
        </a>
        <a href="mailto:emilioskr@gmail.com" className="text-gray-400 hover:text-white transition-colors">
          <FaEnvelope size={24} />
        </a>
      </div>
      <p className="mt-4 text-gray-400">&copy; 2024 Emilio Romero. All rights reserved.</p>
    </footer>
  )
}
