import React, { createContext, useContext, useState, ReactNode } from 'react';

type Language = 'en' | 'es';

interface TranslationContextProps {
  language: Language;
  setLanguage: (language: Language) => void;
  t: (key: TranslationKey) => string; // Use TranslationKey here
}

const translations = {
  en: {
    home: 'Home',
    about: 'About',
    projects: 'Projects',
    contact: 'Contact',
    // Add more translations here
  },
  es: {
    home: 'Inicio',
    about: 'Acerca de',
    projects: 'Proyectos',
    contact: 'Contacto',
    // Add more translations here
  },
};

// Define a type for the translation keys
type TranslationKey = keyof typeof translations['en'];

const TranslationContext = createContext<TranslationContextProps | undefined>(undefined);

export const TranslationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState<Language>('en');

  const t = (key: TranslationKey) => {
    return translations[language][key] || key;
  };

  return (
    <TranslationContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('useTranslation must be used within a TranslationProvider');
  }
  return context;
};
