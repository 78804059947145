"use client"

import { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useMediaQuery } from 'react-responsive'
import { FaGlobe } from 'react-icons/fa' // Importing a globe icon for translation
import logo from '../assets/img/er_logo.png' // Import the PNG logo
import { useTranslation } from '../contexts/TranslationContext' // Import the useTranslation hook

export default function Component() {
    const [isOpen, setIsOpen] = useState(false)
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const { language, setLanguage, t } = useTranslation() // Destructure the translation context

    useEffect(() => {
        if (!isMobile && isOpen) {
            setIsOpen(false)
        }
    }, [isMobile, isOpen]) // Added 'isOpen' to the dependency array

    const menuItems = [
        { name: t('home'), href: '#' },
        { name: t('about'), href: '#about' },
        { name: t('projects'), href: '#projects' },
        { name: t('contact'), href: '#contact' },
    ]

    const variants = {
        open: { opacity: 1, x: 0 },
        closed: { opacity: 0, x: "-100%" },
    }

    const scrollToSection = (e: React.MouseEvent<HTMLAnchorElement>, href: string) => {
        e.preventDefault()
        if (href === '#') {
            // Scroll to the top of the page for the home link
            window.scrollTo({ top: 0, behavior: 'smooth' })
        } else {
            const section = document.querySelector(href)
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' })
            }
        }
        setIsOpen(false) // Close mobile menu after clicking
    }

    const closeMenu = () => {
        setIsOpen(false)
    }

    const toggleLanguage = () => {
        setLanguage(language === 'en' ? 'es' : 'en')
    }

    return (
        <nav className="relative bg-black text-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex-shrink-0">
                        <img 
                            src={logo} 
                            alt="Emilio Romero Logo" 
                            className="h-8 w-auto sm:h-10"
                        />
                    </div>
                    <div className="hidden md:flex items-center">
                        <div className="ml-10 flex items-baseline space-x-4">
                            {menuItems.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className="px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 transition duration-150 ease-in-out"
                                    onClick={(e) => scrollToSection(e, item.href)}
                                >
                                    {item.name}
                                </a>
                            ))}
                        </div>
                        <button 
                            className="ml-4 p-2 rounded-md text-sm font-medium text-white hover:bg-gray-700 transition duration-150 ease-in-out"
                            onClick={toggleLanguage} // Add onClick handler
                        >
                            <FaGlobe size={20} />
                        </button>
                    </div>
                    <div className="md:hidden">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white relative z-50"
                        >
                            <span className="sr-only">Open main menu</span>
                            <div className={`hamburger ${isOpen ? 'open' : ''}`}>
                                <span className="line"></span>
                                <span className="line"></span>
                                <span className="line"></span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={variants}
                        transition={{ duration: 0.5, ease: "easeInOut" }}
                        className="md:hidden fixed inset-0 z-40 bg-black bg-opacity-90 backdrop-blur-sm flex items-center justify-center"
                        onClick={closeMenu}
                    >
                        <div className="space-y-4 text-center" onClick={(e) => e.stopPropagation()}>
                            {menuItems.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className="block text-2xl font-medium text-white hover:bg-gray-700 transition duration-150 ease-in-out py-2 rounded-md"
                                    onClick={(e) => scrollToSection(e, item.href)}
                                >
                                    {item.name}
                                </a>
                            ))}
                            <button 
                                className="mt-4 p-2 rounded-md text-lg font-medium text-white hover:bg-gray-700 transition duration-150 ease-in-out"
                                onClick={toggleLanguage} // Add onClick handler
                            >
                                <FaGlobe size={24} />
                            </button>
                        </div>
                        <div className="absolute inset-0 pointer-events-none">
                            {[...Array(50)].map((_, i) => (
                                <div
                                    key={i}
                                    className="absolute rounded-full bg-white"
                                    style={{
                                        top: `${Math.random() * 100}%`,
                                        left: `${Math.random() * 100}%`,
                                        width: `${Math.random() * 3}px`,
                                        height: `${Math.random() * 3}px`,
                                        opacity: Math.random() * 0.7,
                                        animation: `twinkle ${Math.random() * 5 + 3}s infinite`,
                                    }}
                                />
                            ))}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            <style jsx>{`
                .hamburger {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                    z-index: 50;
                }

                .line {
                    width: 100%;
                    height: 2px;
                    background-color: currentColor;
                    transition: transform 0.3s ease, opacity 0.3s ease;
                }

                .hamburger.open .line:nth-child(1) {
                    transform: translateY(8px) rotate(45deg);
                }

                .hamburger.open .line:nth-child(2) {
                    opacity: 0;
                }

                .hamburger.open .line:nth-child(3) {
                    transform: translateY(-8px) rotate(-45deg);
                }

                @keyframes twinkle {
                    0% { opacity: 0; }
                    50% { opacity: 1; }
                    100% { opacity: 0; }
                }
            `}</style>
        </nav>
    )
}
