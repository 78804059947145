"use client"

import { useEffect, useState } from 'react'
import { motion, useScroll, useSpring, AnimatePresence } from 'framer-motion'
//import { FaGithub, FaLinkedin, FaEnvelope } from 'react-icons/fa'
import { SiJavascript, SiReact, SiNodedotjs, SiPython, SiDocker, SiDjango, SiTypescript, SiTailwindcss, SiGit, SiLinux, SiGooglecloud, SiMysql } from 'react-icons/si'
import Navbar from '../components/Navbar'
import '../assets/css/styles.css' // Import the custom CSS file
import creandoartechileLogo from '../assets/img/creandoartechile_logo.png'
import gaudeLogo from '../assets/img/gaude_logo.png'
import fedsqLogo from '../assets/img/fedsq_logo.png'
import JobExperienceTimeline from '../components/Timeline'
import ScrollToTopButton from '../components/ScrollToTopButton' // Import the ScrollToTopButton component
import Footer from '../components/Footer'
import Loader from '../components/Loader'; // Import the Loader component
import emailjs from 'emailjs-com';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';

export default function Portfolio() {
  const [isLoading, setIsLoading] = useState(true); // State to manage loading
  const { scrollYProgress } = useScroll()
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  })

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Loader />; // Show loader while loading
  }

  return (
    <div className="min-h-screen bg-black text-white overflow-hidden">
      <StarryBackground />
      <motion.div className="fixed top-0 left-0 right-0 h-1 bg-gradient-to-r from-blue-900 to-indigo-900 transform-origin-0"
        style={{ scaleX }} />
      <Navbar />
      <main className="container mx-auto px-6 py-20">
        <Hero />
        <About />
        <Skills />
        <Projects />
        <JobExperienceTimeline /> {/* Add the Timeline component here */}
        <Contact />
      </main>
      <Footer /> {/* Add the Footer component here */}
      <ScrollToTopButton /> {/* Add the ScrollToTopButton component here */}
    </div>
  )
}

function StarryBackground() {
  const [stars, setStars] = useState<{ id: number; x: number; y: number; size: number; opacity: number; duration: number; }[]>([])

  useEffect(() => {
    const generateStars = () => {
      const newStars = []
      for (let i = 0; i < 100; i++) {
        newStars.push({
          id: i,
          x: Math.random() * 100,
          y: Math.random() * 100,
          size: Math.random() * 2 + 1,
          opacity: Math.random(),
          duration: Math.random() * 3 + 2,
        })
      }
      setStars(newStars)
    }

    generateStars()
  }, [])

  return (
    <div className="fixed inset-0 z-0">
      {stars.map((star) => (
        <motion.div
          key={star.id}
          className="absolute rounded-full bg-white"
          style={{
            left: `${star.x}%`,
            top: `${star.y}%`,
            width: star.size,
            height: star.size,
          }}
          animate={{
            opacity: [star.opacity, 1, star.opacity],
            scale: [1, 1.2, 1],
          }}
          transition={{
            duration: star.duration,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        />
      ))}
    </div>
  )
}

function Hero() {
  return (
    <motion.section
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="h-screen flex flex-col justify-center items-center text-center"
    >
      <motion.h1
        className="text-6xl md:text-8xl font-bold mb-4 bg-clip-text text-transparent"
        initial={{ backgroundPosition: '0% 50%' }}
        animate={{ backgroundPosition: ['0% 50%', '100% 50%', '0% 50%'] }}
        transition={{ duration: 20, ease: 'easeInOut', repeat: Infinity }}
        style={{
          backgroundImage: 'linear-gradient(270deg, #1e3a8a, #3b82f6, #9333ea, #f43f5e, #1e3a8a)',
          backgroundSize: '400% 400%',
        }}
      >
        Emilio Romero
      </motion.h1>
      <h2 className="text-2xl md:text-3xl mb-8">Full Stack Developer</h2>
      <p className="text-xl max-w-2xl mb-12">
        Crafting digital experiences that are out of this world
      </p>
      <a href="#contact" className="bg-gradient-to-r from-blue-900 to-indigo-900 text-white font-bold py-3 px-6 rounded transition-colors animate-gradient hover:bg-gradient-to-r hover:from-purple-900 hover:to-pink-900 hover:shadow-lg hover:shadow-purple-500/50 hover:animate-nebula">
        Get in Touch
      </a>
    </motion.section>
  )
}

function About() {
  return (
    <motion.section
      id="about"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      className="py-20"
    >
      <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center">About Me</h2>
      <div className="max-w-3xl mx-auto">
        <p className="text-lg mb-6">
          Hello! I'm Emilio Romero, a passionate full stack developer with a love for creating innovative and user-friendly web applications. With a background in computer science and years of experience in the industry, I specialize in building robust and scalable solutions that make a difference.
        </p>
        <p className="text-lg">
          When I'm not coding, you can find me exploring new technologies, contributing to open-source projects, or gazing at the stars. I believe in continuous learning and pushing the boundaries of what's possible in web development.
        </p>
      </div>
    </motion.section>
  )
}

function Skills() {
  const skills = [
    { name: "JavaScript", icon: <SiJavascript className="text-yellow-400" size={48} /> },
    { name: "React", icon: <SiReact className="text-blue-400" size={48} /> },
    { name: "Node.js", icon: <SiNodedotjs className="text-green-500" size={48} /> },
    { name: "Python", icon: <SiPython className="text-blue-500" size={48} /> },
    { name: "Docker", icon: <SiDocker className="text-blue-600" size={48} /> },
    // Add new technologies here
    { name: "Django", icon: <SiDjango className="text-green-700" size={48} /> },
    { name: "TypeScript", icon: <SiTypescript className="text-blue-500" size={48} /> },
    { name: "TailwindCSS", icon: <SiTailwindcss className="text-teal-400" size={48} /> },
    { name: "Git", icon: <SiGit className="text-red-500" size={48} /> },
    { name: "Linux", icon: <SiLinux className="text-white" size={48} /> },
    { name: "Google", icon: <SiGooglecloud className="text-blue-300" size={48} /> },
    { name: "MySQL", icon: <SiMysql className="text-blue-700" size={48} /> },
  ]

  return (
    <motion.section
      id="skills"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      className="py-20"
    >
      <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">Skills & Technologies</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8">
        {skills.map((skill, index) => (
          <motion.div
            key={skill.name}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            className="flex flex-col items-center"
          >
            {skill.icon}
            <span className="mt-2 text-lg">{skill.name}</span>
          </motion.div>
        ))}
      </div>
    </motion.section>
  )
}

function Projects() {
  const projects = [
    {
      title: "Creando Arte Chile",
      description: "A platform showcasing the vibrant art scene in Chile.",
      image: creandoartechileLogo,
      stack: [
        <SiReact className="text-blue-400" size={24} />,
        <SiDjango className="text-green-700" size={24} />,
        <SiMysql className="text-blue-700" size={24} />, // Representing SQL
      ],
      url: "https://creandoartechile.cl/"
    },
    {
      title: "Gaud-e",
      description: "An innovative e-learning platform for creative minds.",
      image: gaudeLogo,
      stack: [
        <SiDjango className="text-green-700" size={24} />,
        <SiPython className="text-blue-500" size={24} />,
        <SiTailwindcss className="text-teal-400" size={24} />,
      ],
      url: "https://www.gaud-e.app/"
    },
    {
      title: "Feds'Q",
      description: "A community-driven platform for food enthusiasts.",
      image: fedsqLogo,
      stack: [
        <SiDjango className="text-green-700" size={24} />,
        <SiReact className="text-blue-400" size={24} />,
        <SiPython className="text-blue-500" size={24} />,
      ],
      url: "https://legendary-kelpie-b17f6a.netlify.app/#/mesa/1"
    },
  ]

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left - rect.width / 2;
    const y = e.clientY - rect.top - rect.height / 2;
    const rotateX = (y / rect.height) * 15; // Adjust the multiplier for more/less rotation
    const rotateY = (x / rect.width) * -15; // Adjust the multiplier for more/less rotation

    card.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.currentTarget.style.transform = 'perspective(1000px) rotateX(0deg) rotateY(0deg)';
  };

  return (
    <motion.section
      id="projects"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      className="py-20"
    >
      <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">Featured Projects</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {projects.map((project, index) => (
          <motion.div
            key={project.title}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            onMouseMove={(e) => handleMouseMove(e, index)}
            onMouseLeave={handleMouseLeave}
            className="bg-black bg-opacity-50 backdrop-blur-md rounded-lg overflow-hidden shadow-lg"
          >
            <img src={project.image} alt={project.title} className="w-full h-48 object-cover" />
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-2">
                <a href={project.url} target="_blank" rel="noopener noreferrer" className="hover:underline">
                  {project.title}
                </a>
              </h3>
              <p className="text-gray-400 mb-4">{project.description}</p>
              <div className="flex space-x-2">
                {project.stack.map((icon, idx) => (
                  <div key={idx} className="flex items-center">
                    {icon}
                  </div>
                ))}
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.section>
  )
}

function Tooltip({ message, type }: { message: string, type: 'success' | 'error' }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      className={`fixed top-4 right-4 p-4 rounded-md shadow-lg flex items-center ${
        type === 'success' ? 'bg-green-500' : 'bg-red-500'
      } text-white`}
    >
      {type === 'success' ? <FaCheckCircle className="mr-2" /> : <FaExclamationCircle className="mr-2" />}
      {message}
    </motion.div>
  );
}

function Contact() {
  const [tooltip, setTooltip] = useState<{ message: string, type: 'success' | 'error' } | null>(null);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    emailjs.sendForm('service_p5k9lu4', 'template_p5omrad', e.currentTarget, 'rJd714XRHw4-iC1KG')
      .then((result) => {
          console.log(result.text);
          setTooltip({ message: 'Message sent successfully!', type: 'success' });
      }, (error) => {
          console.log(error.text);
          setTooltip({ message: 'Failed to send the message, please try again.', type: 'error' });
      });

    e.currentTarget.reset(); // Reset the form after submission

    // Hide the tooltip after 3 seconds
    setTimeout(() => setTooltip(null), 3000);
  };

  return (
    <motion.section
      id="contact"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      className="py-20"
    >
      <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">Get in Touch</h2>
      <form className="max-w-lg mx-auto" onSubmit={sendEmail}>
        <div className="mb-6">
          <label htmlFor="name" className="block text-sm font-medium mb-2">Name</label>
          <input type="text" id="name" name="name" required className="w-full px-3 py-2 bg-gray-800 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500" />
        </div>
        <div className="mb-6">
          <label htmlFor="email" className="block text-sm font-medium mb-2">Email</label>
          <input type="email" id="email" name="email" required className="w-full px-3 py-2 bg-gray-800 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500" />
        </div>
        <div className="mb-6">
          <label htmlFor="message" className="block text-sm font-medium mb-2">Message</label>
          <textarea id="message" name="message" rows={4} required className="w-full px-3 py-2 bg-gray-800 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"></textarea>
        </div>
        <button type="submit" className="w-full bg-gradient-to-r from-blue-900 to-indigo-900 hover:from-indigo-900 hover:to-blue-900 text-white font-bold py-3 px-6 rounded-md transition-colors">
          Send Message
        </button>
      </form>
      <AnimatePresence>
        {tooltip && <Tooltip message={tooltip.message} type={tooltip.type} />}
      </AnimatePresence>
    </motion.section>
  )
}
